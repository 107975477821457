<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-03-13 09:11:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-27 21:17:53
-->
<template>
  <router-view></router-view>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  watch: {
    /**
     * 监听路由变化
     */
    $route() {
      if (process.env.NODE_ENV !== "development") {
        var url = location.href.split("#")[0];
        if (!this.$route.meta.customShare) {
          this.$http
            .post("v1/common/wx_share", {
              url: url,
            })
            .then((res) => {
              if (res.code == 0 && res.data) {
                const config = res.data;
                wx.config({
                  appId: config.appId, // appID 公众号的唯一标识
                  timestamp: config.timestamp, // 生成签名的时间戳
                  nonceStr: config.noncestr, //  生成签名的随机串
                  signature: config.signature, // 生成的签名
                  jsApiList: [
                    "updateAppMessageShareData", // 分享到朋友
                    "updateTimelineShareData", // 分享到朋友圈
                  ],
                });
                var meta = Object.assign(
                  {
                    title: "友邦北京2023年度荣誉盛典\r\n线上特别策划",
                    desc: "助力年度荣誉 感受璀璨光芒",
                  },
                  this.$route.meta
                );
                wx.ready(() => {
                  var shareData = {
                    title: meta.title,
                    desc: meta.desc,
                    link: url, // 分享后的地址
                    imgUrl: config.logo,
                  };
                  // //点击要去分享
                  wx.updateAppMessageShareData(shareData);
                  wx.updateTimelineShareData(shareData);
                });
                wx.error(function (res) {
                });
              }
            });
        }

        this.$http.post("v1/common/pv/add", {
          url: url,
        });
      }
    },
  },
  created() {},
};
</script>
