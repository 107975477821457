/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-03-16 00:06:30
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-11-16 11:17:52
 */
import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import config from "@/config/config";
import Layout from "@/layout";

const routes = [
  {
    path: "/rank",
    name: "rank",
    component: () => import("@/views/rank"),
    meta: {
      title: `金额排行榜`,
      desc: "",
    },
  },
  {
    path: "/rank/count",
    name: "rank_count",
    component: () => import("@/views/rank/count"),
    meta: {
      title: `件数排行榜`,
      desc: "",
    },
  },
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login"),
    meta: {
      title: `请输入您的信息`,
      desc: "",
    },
  },
  {
    path: "/target",
    name: "target",
    component: () => import("@/views/target"),
    meta: {
      title: `Ascend2025启动会目标`,
      desc: "",
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/rank/test.vue"),
    meta: {
      title: `Ascend2025启动会目标`,
      desc: "",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

//需要检测登录的页面
var arr = [
  "voice_enter",
  "slogan_enter",
  "slogan_dtl",
  "slogan_ranked",
  "photo_enter",
  "photo_ranked",
  "photo_dtl",
];

/**
 * 前置守卫
 */
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (arr.includes(to.name)) {
    var token = localStorage.getItem(config.TOKEN);
    if (!token) {
      //记录需要回跳的地址
      localStorage.setItem(config.RETURN_URL, to.fullPath);
      console.log(to.fullPath);
      next("/login");
      return;
    }
  }
  next();
});
/**
 * 后置守卫
 */
router.afterEach((to, from, next) => {
  document.title = to.meta?.title ?? "友邦";
  NProgress.done();
});

export default router;
