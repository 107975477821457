/*
 * @Description: 配置参数
 * @Author: 琢磨先生
 * @Date: 2022-05-09 21:46:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-16 00:07:18
 */

export default {
  /**
   * api请求host地址
   */
  HOST:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5195"
      : "https://api.yb.itxiangtian.com",
  /**
   * 本地缓存token名称
   */
  TOKEN: "token",

  /**
   * 应用编码
   */
  APP_CODE: "consumer",
  /**
   * 项目app key
   */
  APP_KEY: "yb_h5",
  /**
   * 接口KEY
   */
  API_KEY: "yb_h5_api",

  /**
   * 用户存储
   */
  USER_KEY: "yb_mb_key",

  /**
   * 投票用户的token
   */
  USER_TOKEN:'yb_user_token',

  /**
   * 记录登录回跳的地址
   */
  RETURN_URL:'return_url',
};
